import React from 'react';
import { Button, Checkbox, Form, Icon, Input, message, Dropdown, Menu, Card, Row, Col, Layout } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { auth, database } from '../firebase/firebase';

import {
	hideMessage,
	showAuthLoader,
	userFacebookSignIn,
	userGithubSignIn,
	userGoogleSignIn,
	userSignIn,
	userTwitterSignIn
} from 'appRedux/actions/Auth';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from 'components/CircularProgress/index';
import Topbar from './Topbar/index';
import HorizontalDefault from './Topbar/HorizontalDefault/index';

class Info extends React.Component {
	componentDidUpdate() {}

	render() {
		return (
			<div className="gx-app-login-wrap">
				<HorizontalDefault />
				<div className="gx-app-login-wrap">

				<div className="gx-app-login-container">
					<div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-18 ant-col-xl-19 ant-col-xxl-20">
					<Card title="Acerca de">
						<h4>
						El Gastro Tour | Chinoin tiene como propósito principal ofrecer una herramienta práctica para facilitar la autoevaluación y la obtención de puntaje con fines de recertificación por parte de los principales Consejos de medicina en México.
<br></br><br></br>
Los constantes progresos de la medicina en todas sus disciplinas y ramas, hacen indispensable la constante actualización, particularmente en lo que se refiere a los avances en los abordajes diagnósticos y terapéuticos de las entidades patológicas más frecuentes en el ejercicio clínico.
<br></br><br></br>
El contenido de los programas que conforman las evaluaciones del Gastro Tour | Chinoint, son desarrollados por médicos expertos en cada tema y supervisados por los Colegios y Sociedades, quienes otorgan su aval académico.
<br></br><br></br>
Esperamos que el Gastro Tour | Chinoin, sirva como un puente que acorte las distancias entre los médicos que día tras día se afanan en ofrecer a sus pacientes una atención óptima para devolverles la salud, y las instituciones académicas, gremiales y normativas, comprometidas con la mejora permanente de la calidad de la atención médica.
					
					
						</h4>
						
					</Card>
					
				</div>
				</div>
			</div>
			</div>
		);
	}
}

export default Info;
