import firebase from "firebase";

// Initialize Firebase
const config = {
  databaseURL: "https://gastrotourchinoin.firebaseio.com",
  apiKey: "AIzaSyA0pego3DYcqOWyMWpjPFqIYe3wMfuAqPo",
  authDomain: "gastrotourchinoin.firebaseapp.com",
  projectId: "gastrotourchinoin",
  storageBucket: "gastrotourchinoin.appspot.com",
  messagingSenderId: "672802386467",
  appId: "1:672802386467:web:9f93d95052d4515c65f796",
  measurementId: "G-ZE1RRD5LCZ"
};
firebase.initializeApp(config);
firebase.auth().languageCode = 'es'
const auth = firebase.auth();
const functions = firebase.functions();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

const database = firebase.firestore();

export {
  database,
  auth,
  functions,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider
};
